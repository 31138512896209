<template lang="pug">
article.about
  .about__cont.cont
    h1.about__caption.caption Что такое ChiedoHome?
    p.about__text-main.text-big Компания ChiedoHome занимается продажей качественной мебели собственного производства.
    p.about__text-main.text-big
      | Мы полностью оправдываем возложенные на нас ожидания. Наши клиенты становятся обладателями предельно
      | качественной, максимально комфортной и долговечной мебели.
    p.about__text-main.text-big Вся мебель доставляется напрямую со склада нашей фабрики и именно поэтому у неё низкая цена.
    h2.about__title.title О производстве
    p.about__text.text-big
      | Мы сами производим мягкую мебель, деревянные стулья и стулья на металлокаркасе, столы, текстиль, мебель лофт,
      | чтобы обеспечивать покупателей качественной и недорогой продукцией.
    p.about__text.text-big
      | В видео мы расскажем и покажем как рождаются новые продукты — те самые, которые используются многими клиентами
      | во всех странах СНГ. Посмотрите и убедитесь — качество и внешний вид изделий соответствуют самым высоким требованиям.
    .about__video-box
      iframe.about__video(
        src="https://www.youtube.com/embed/-33R2d-pN0o"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      )
</template>

<script>
import '@/assets/styles/components/about.sass'

export default {
  name: 'About'
}
</script>
