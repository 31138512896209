<template lang="pug">
head-meta(title-page="О бренде ChiedoHome", description="В интернет магазине ChiedoHome вы можете купить качественную мебель с доставкой" )
about
</template>

<script>
import About from '../components/about/About'
import HeadMeta from '../components/_layout/HeadMeta'

export default {
  name: 'AboutPage',
  components: { HeadMeta, About }
}
</script>
